<template>

  <div>

    <!--    <user-list-add-new-->
    <!--      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"-->
    <!--      :role-options="resellerOptions"-->
    <!--      :plan-options="planOptions"-->
    <!--      @refetch-data="refetchData"-->
    <!--    />-->

    <!-- Filters -->
    <users-list-filters
      :reseller-filter.sync="resellerFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :reseller-options="resellerOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button @click="startActive()">
              start all active
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :href="'https://www.twitch.tv/' + data.item.twitchname"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.twitchname }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!--        &lt;!&ndash; Column: Role &ndash;&gt;-->
        <!--        <template #cell(role)="data">-->
        <!--          <div class="text-nowrap">-->
        <!--            <feather-icon-->
        <!--              :icon="resolveUserRoleIcon(data.item.role)"-->
        <!--              size="18"-->
        <!--              class="mr-50"-->
        <!--              :class="`text-${resolveUserRoleVariant(data.item.role)}`"-->
        <!--            />-->
        <!--            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>-->
        <!--          </div>-->
        <!--        </template>-->

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item)}`"
            class="text-capitalize"
          >
            {{ resolveStatus(data.item) }}

          </b-badge>
        </template>

        <!--        Column: MinimumVIewers vs ActualViewers-->
        <template #cell(viewers)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserViewerStatusVariant(data.item)}`"
            class="text-capitalize"
          >
            <!--            {{ data }}-->
            {{ resolveViews(data.item) }}

          </b-badge>
        </template>

        <!-- Column: Duration -->
        <template #cell(duration)="data">
          <b-progress
            :max="data.item.durationTotal"
            :variant="`${resolveProgressBarVariant(data.item)}`"
          >
            <b-progress-bar :value="data.item.durationTotal - data.item.duration">
              <strong>{{ data.item.durationTotal - data.item.duration }} / {{ data.item.durationTotal }}</strong>
            </b-progress-bar>

          </b-progress>
          <!--          <b-badge-->
          <!--            pill-->
          <!--            :variant="`light-${resolveUserStatusVariant(data.item.duration)}`"-->
          <!--            class="text-capitalize"-->
          <!--          >-->
          <!--            {{ data.item.duration }}-->
          <!--          </b-badge>-->
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>
            <b-dropdown-item @click="stopPackage(data.item)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">End CurrentPackage</span>
            </b-dropdown-item>
            <b-dropdown-item @click="extendPackage(data.item)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Extend CurrentPackage</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BProgress,
  BProgressBar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    UsersListFilters,
    UserListAddNew,
    BProgress,
    BProgressBar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      test: '123',
    }
  },
  mounted() {
    this.refreshForCurrentViews()
  },
  methods: {
    refreshForCurrentViews() {
      const sx = this.$firebase.firestore()
      sx.collection('User').where('Botpanel.Active', '==', true).onSnapshot(result => {
        result.forEach(x => {
          this.sendPostRequest(x.data().twitchname).then(newViewers => {
            sx.collection('User')
              .doc(x.id).set({
                Botpanel: {
                  currentViews: newViewers.data.user.stream.viewersCount,
                },
              }, { merge: true }).then(() => {
                console.log('SUCCESS UPDATED')
              })
              .catch(err => {
                console.log(err)
              })
          })
        })
      })
    },
    startActive() {
      const sx = this.$firebase.firestore()
      sx.collection('User').where('Botpanel.Active', '==', true).get().then(result => {
        sx.collection('System')
          .doc('NGROK')
          .get()
          .then(snap2 => {
            let count = 0
            result.forEach(x => {
              count += 1
              setTimeout(() => {
                fetch(`https://${snap2.data().Link}.ngrok.io/start/${x.id}`)
                  .then(response => response.json())
              }, 3000 * count)
              console.log(`https://${snap2.data().Link}.ngrok.io/start/${x.id}/${x.data().Botpanel.Chatbot}/${x.data().twitchname}/${x.data().Botpanel.Views}/${x.data().Botpanel.chatinterval[0]}/${x.data().Botpanel.chatinterval[1]}`)
            })
          })
      })
    },
    extendPackage(item) {
      const sx = this.$firebase.firestore()
      const obj = {
        TotalDays: item.durationTotal + 1,
      }
      // sx.collection('userTest').doc(item.id).update({ test: obj })
      sx.collection('User').doc(item.id).set({ License: obj }, { merge: true })
    },
    stopPackage(item) {
      const sx = this.$firebase.firestore()
      let obj = {}
      if (item.durationTotal === undefined) {
        obj = {
          licenseKeyOld: item.licenseKey, licensekey: 'none', TotalDays: 0, TotalDaysOld: 0,
        }
      } else {
        obj = {
          licenseKeyOld: item.licenseKey, licensekey: 'none', TotalDays: 0, TotalDaysOld: item.durationTotal,
        }
      }
      const bpObj = {
        Active: false,
      }
      // sx.collection('userTest').doc(item.id).update({ test: obj })
      sx.collection('User').doc(item.id).set({ License: obj, Package: 'None', Botpanel: bpObj }, { merge: true })
    },
    resolveViews(item) {
      if (item.Botpanel?.currentViews !== null) {
        return `${item.Botpanel.currentViews}/${item.Botpanel?.Views}`
      }
      return `0 / ${item.Botpanel?.Views}`
    },

    async sendPostRequest(username) {
      const userAgent = {
        Windows: {
          CHROME: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.104 Safari/537.36',
          FIREFOX: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:84.0) Gecko/20100101 Firefox/84.0',
        },
        Linux: {
          CHROME: 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.96 Safari/537.36',
          FIREFOX: 'Mozilla/5.0 (X11; Linux x86_64; rv:85.0) Gecko/20100101 Firefox/85.0',
        },
      }
      const clientId = 'kimne78kx3ncx6brgo4mv6wki5h1ko'
      const jsonOperation = {
        operationName: 'VideoPlayerStreamInfoOverlayChannel',
        variables: {
          channel: username,
        },
        extensions: {
          persistedQuery: {
            version: 1,
            sha256Hash: 'a5f2e34d626a9f4f5c0204f910bab2194948a9502089be558bb6e779a9e1b3d2',
          },
        },
      }

      const response = await fetch(
        'https://gql.twitch.tv/gql', {
          method: 'POST',
          headers: {
            Authorization: 'Oauth c7oso8b4qr09bd3gno0yqykvgv86wy',
            'Client-Id': clientId,
            'User-Agent': userAgent,
          },
          body: JSON.stringify(jsonOperation),
        },
      )
      return response.json()
    },

    resolveStatus(item) {
      console.log(item.Botpanel?.Active)
      if (item.Botpanel?.Active) {
        return 'Started'
      }
      return 'Stopped'
    },
    getTwitchLink(user) {
      return `https://www.twtich.tv/${user.twitchname}`
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const resellerOptions = [
      { label: 'None', value: 'none' },
      { label: 'Faker', value: 'faker' },
      // { label: 'Editor', value: 'editor' },
      // { label: 'Maintainer', value: 'maintainer' },
      // { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Starter', value: 'starter' },
      { label: 'Headstarter', value: 'headstarter' },
      { label: 'Partner', value: 'partner' },
      { label: 'Custom', value: 'custom' },
      { label: 'None', value: 'none' },

    ]

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserViewerStatusVariant,

      resolveProgressBarVariant,
      // Extra Filters
      resellerFilter,
      planFilter,
      statusFilter,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserViewerStatusVariant,

      resolveProgressBarVariant,

      resellerOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      resellerFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>5

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
